import Button from './Button';
import InputCheckbox from './Input/InputCheckbox';
import { PopUpControllerService } from '@/__generated__/FrontApi';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LocalStorage from '@/services/LocalStorage';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    top: -20px;
  }
  to {
    top: 0;
  }
`;

const ModalDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #fff;
  animation: ${slideIn} 0.15s linear;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  ${breakpoint(`mobile`)} {
    top: 15px;
    left: 15px;
    right: 15px;
    transform: none;
    img {
      width: 100%;
    }
  }
`;

const PopupContainer = styled.div``;

const PopupFooter = styled.div`
  padding: 15px;
  ${breakpoint(`mobile`)} {
    padding: 10px 24px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonClose = styled(Button)`
  padding: 5px 10px;
  font-size: 16px;
`;

export interface EmergencyPopupData {
  attGrpNoMobile: string;
  attGrpNoPc: string;
  indexNumber: string;
  kind: string;
  targetBlankYn: 'Y' | 'N';
  targetUrl: string;
  title: string;
}

interface EmergencyPopupProps {
  // 1: 메인, 2: 마이페이지
  kind: number;
}

const EmergencyPopup: VFC<EmergencyPopupProps> = ({ kind, ...props }) => {
  const [ignore, setIgnore] = useState(false);
  const [popupData, setPopupData] = useState<EmergencyPopupData>();

  // 팝업 24시간동안 무시
  const ignorePopup = useCallback(() => {
    if (popupData) {
      LocalStorage.setItem(`ignoredPopupIndex${kind}`, popupData.indexNumber);
      LocalStorage.setItem(`ignoredPopupAt${kind}`, `${new Date().getTime()}`);
    }
  }, [popupData]);

  // 팝업 닫기
  const close = useCallback(() => {
    if (ignore) {
      ignorePopup();
    }
    setPopupData(undefined);
  }, [ignore, ignorePopup]);

  useEffect(() => {
    PopUpControllerService.listUsingGet({
      kind,
    }).then(({ resultCode, data }) => {
      if (resultCode === `success`) {
        if (!data || data.displayStatusCode === '2') {
          return;
        }
        const ignoredPopupIndex = LocalStorage.getItem(
          `ignoredPopupIndex${kind}`,
        );
        const ignoredPopupAt = LocalStorage.getItem(`ignoredPopupAt${kind}`);
        // 저장된 팝업 무시 데이터가 있다면
        if (ignoredPopupIndex && ignoredPopupAt && data) {
          const timeout = 24 * 60 * 60 * 1000; // 24시간
          if (
            data.indexNumber === ignoredPopupIndex &&
            Number(ignoredPopupAt) + timeout > new Date().getTime()
          ) {
            // 정보가 일치하고, timeout 이전이면 무시
            return;
          }
        }

        setPopupData(data);
      }
    });
  }, [kind]);

  if (!popupData) {
    return null;
  }

  return createPortal(
    <ModalDialog {...props}>
      <PopupContainer>
        <a
          href={popupData.targetUrl}
          target={popupData.targetBlankYn === `Y` ? `_blank` : ``}
          rel="noreferrer"
        >
          <img src={popupData.attGrpNoPc} alt={popupData.title} />
        </a>
        <PopupFooter>
          <InputCheckbox
            css={`
              margin-right: 10px;
            `}
            checked={ignore}
            onChange={(e) => setIgnore(e.target.checked)}
            label="1일동안 보지 않기"
          />
          <ButtonClose onClick={close}>닫기</ButtonClose>
        </PopupFooter>
      </PopupContainer>
    </ModalDialog>,
    document.body,
  );
};

export default EmergencyPopup;
